<template>
  <div class="h-full relative">
    <DialogHeader
      :title="useT('workspace.integrations')"
      size="large"
      show-close
      class="absolute top-0 left-0 z-20 bg-white w-full rounded-tr-[var(--el-dialog-border-radius)]"
      @close="useDialogStore().close('settings')"
    />
    <div class="h-full overflow-auto">
      <div class="py-16 px-4 sm:px-6 divide-y divide-gray-200">
        <div class="flex justify-between items-start sm:items-center flex-col sm:flex-row py-6">
          <div class="flex mr-2">
            <Avatar
              size="md"
              icon="custom/logo-airtable"
              type="square"
            />
            <div class="max-w-sm ml-4">
              <div class="flex flex-col-reverse sm:flex-row gap-2 items-start sm:items-center mt-1 mb-1">
                <strong class="leading-relaxed">
                  {{ useT('workspace.integrations.airtable.title') }}
                </strong>

                <Badge
                  v-if="useWorkspaceStore().integrations?.airtable?.isEnabled"
                  type="success"
                  :text="useT('workspace.integrations.customDomain.connected')"
                />
              </div>
              <div
                class="text-gray-600 text-sm"
              >
                {{ useT('workspace.integrations.airtable.description') }}
              </div>
            </div>
          </div>
          <Button
            type="regular-outline"
            class="ml-14 sm:ml-0 mt-4 sm:mt-0"
            @click="() => useDialogStore().open('workspaceAirtable')"
          >
            {{ useT('workspace.integrations.airtable.setUp') }}
          </Button>
        </div>
        <div
          v-loading="isDomainChecking"
          class="flex justify-between items-start sm:items-center flex-col sm:flex-row py-6"
        >
          <div class="flex mr-2">
            <Avatar
              size="md"
              icon="globe-02-1"
              type="square"
            />
            <div class="max-w-sm ml-4">
              <div class="flex flex-col-reverse sm:flex-row gap-2 items-start sm:items-center mt-1 mb-1">
                <strong>
                  {{ useT('workspace.integrations.customDomain.connect') }}
                </strong>

                <Badge
                  :type="isCustomDomainConnected || isReverseProxyEnabled ? 'success' : 'warning'"
                  :text="isCustomDomainConnected || isReverseProxyEnabled ? useT('workspace.integrations.customDomain.connected') : useT('workspace.integrations.customDomain.notConnected')"
                />
              </div>
              <div
                class="text-gray-600 text-sm"
              >
                {{ useT('workspace.integrations.customDomain.description') }}
              </div>
            </div>
          </div>
          <div>
            <div
              v-if="useWorkspaceStore().integrations?.customDomain?.url"
              class="flex gap-3 ml-14 sm:ml-0 mt-4 sm:mt-0"
            >
              <TextInput
                :model-value="useWorkspaceStore().integrations?.customDomain?.url"
                disabled
              />
              <ActionList
                :items="[
                  ...isCustomDomainConnected || isReverseProxyEnabled ? [] : [{
                    id: 'settings',
                    icon: 'settings-01',
                    title: useT('common.edit'),
                  }],
                  {
                    id: 'remove',
                    icon: 'trash-01',
                    title: useT('common.remove'),
                  },
                ]"
                placement="bottom-end"
                @click="(id) => {
                  if (id === 'settings') {
                    useDialogStore().open('workspaceCustomDomainSettings', {
                      onClose: () => checkCustomDomain(),
                    })
                  }
                  if (id === 'remove') {
                    useModalStore().open({
                      type: 'danger',
                      title: useT('workspace.integrations.customDomain.disconnect'),
                      description: useT('common.delete.irreversible'),
                      submitButtonText: useT('common.remove'),
                      cancelButtonText: useT('poly.cancel'),
                      onSubmit: onRemoveConfirmed,
                    })
                  }
                }"
              >
                <Button
                  content-icon="dots-vertical"
                  type="regular-outline"
                  class="flex-shrink-0"
                  :disabled="isReverseProxyEnabled"
                  :disabled-tooltip="isReverseProxyEnabled ? useT('workspace.integrations.customDomain.reverseProxyEnabled') : null"
                />
              </ActionList>
            </div>
            <Button
              v-else
              type="regular-outline"
              class="ml-14 sm:ml-0 mt-4 sm:mt-0"
              @click="() => useDialogStore().open('workspaceCustomDomainAdd', {
                onSubmit: () => useDialogStore().open('workspaceCustomDomainSettings', {
                  onClose: () => checkCustomDomain(),
                }),
              })"
            >
              {{ useT('workspace.integrations.airtable.setUp') }}
            </Button>
          </div>
        </div>
        <div class="flex justify-between items-start sm:items-center flex-col sm:flex-row py-6 border-b border-gray-200">
          <div class="flex mr-2 w-full">
            <Avatar
              size="md"
              icon="key-01"
              type="square"
            />
            <div class="flex-1 w-full ml-4 max-w-sm">
              <div class="flex flex-col-reverse sm:flex-row gap-2 items-start sm:items-center mt-1 mb-1">
                <strong class="leading-relaxed">
                  {{ useT('workspace.integrations.apiKeys.title') }}
                </strong>
              </div>
              <div
                class="text-gray-600 text-sm [&_a]:underline"
                v-html="sanitize(useT('workspace.integrations.apiKeys.description'))"
              />
            </div>
          </div>
          <Button
            type="regular-outline"
            class="ml-14 sm:ml-0 mt-4 sm:mt-0"
            @click="() => useDialogStore().open('workspaceAttenduApiKeys')"
          >
            {{ useT('workspace.integrations.apiKeys.manage') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Refs
const isDomainChecking = ref(false);
const domainCheck = ref(null);
const isRemoving = ref(false);

// Computed
const isCustomDomainConnected = computed(() => domainCheck.value?.verified && !domainCheck.value?.config?.misconfigured);
const isReverseProxyEnabled = computed(() => useWorkspaceStore().integrations?.customDomain?.isReverseProxyEnabled);

// Methods
const checkCustomDomain = async() => {
  if (isReverseProxyEnabled.value) {
    isDomainChecking.value = false;
    return;
  }

  isDomainChecking.value = true;
  domainCheck.value = await useWorkspaceStore().checkCustomDomain();
  isDomainChecking.value = false;
};

const onRemoveConfirmed = async() => {
  isRemoving.value = true;
  await useWorkspaceStore().removeCustomDomain();
  await useWorkspaceStore().fetchWorkspace();
  isRemoving.value = false;

  useToast(useT('workspace.customDomain.disconnected'));
  checkCustomDomain();
};

// Lifecycle
onMounted(() => {
  if (useWorkspaceStore()?.integrations?.customDomain?.url) {
    checkCustomDomain();
  }
});
</script>
